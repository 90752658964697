import Link from "next/link";
import ErrorImage from "./ErrorImage";

export default function ErrorPage() {
  return (
    <div className="mt-4 flex max-h-full flex-col items-center justify-center pb-24 sm:mb-36 sm:mt-12">
      <ErrorImage />
      <h1 className="mb-8 px-4 text-center text-2xl font-semibold md:mb-8 md:mt-4">
        Oops, her skjedde det en feil!
      </h1>
      <Link
        href="/"
        className="rounded-full bg-[#9A4234] px-6 py-3 text-[white] hover:bg-[#7d3429]"
      >
        Gå tilbake til forsiden
      </Link>
    </div>
  );
}
